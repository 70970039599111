import ClvrMainParametersStoreState from './ClvrMainParametersStoreState'
import ClvrMainParametersStoreGetters from './ClvrMainParametersStoreGetters'
import ClvrMainParametersStoreMutations from './ClvrMainParametersStoreMutations'
import ClvrMainParametersStoreActions from './ClvrMainParametersStoreActions'

export default {
    mainParameters: {
        namespaced: false,
        state: ClvrMainParametersStoreState,
        getters: ClvrMainParametersStoreGetters,
        mutations: ClvrMainParametersStoreMutations,
        actions: ClvrMainParametersStoreActions
    }
}
