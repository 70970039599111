import ClvrAlertStoreState from './ClvrAlertStoreState'
import ClvrAlertStoreGetters from './ClvrAlertStoreGetters'
import ClvrAlertStoreMutations from './ClvrAlertStoreMutations'
import ClvrAlertStoreActions from './ClvrAlertStoreActions'

export default {
    alertStore: {
        namespaced: false,
        state: ClvrAlertStoreState,
        getters: ClvrAlertStoreGetters,
        mutations: ClvrAlertStoreMutations,
        actions: ClvrAlertStoreActions
    }
}
