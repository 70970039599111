import { allergyCategoryEnum } from '../../../dPpatients/vue/Enum/allergyCategoryEnum'
import Diet from '../../../dPprescription/vue/Classes/Diet'
import { DietTypeEnum } from '../../../dPpatients/vue/Enum/DietTypeEnum'

export default class AllergyHelper {
    public static getStoreKey(categoryId: number): string {
        switch (categoryId) {
            case allergyCategoryEnum.allergy:
                return 'allergiesPatient'
            case allergyCategoryEnum.intolerance:
                return 'intolerancesPatient'
            case allergyCategoryEnum.aversion:
                return 'aversionsPatient'
            default:
                return 'allergiesImmunePatient'
        }
    }

    public static setDietInProgress(diets: Diet[]): void {
        diets.forEach((result) => {
            if (result.refFood && result.refFood.type && result.refFood.type !== DietTypeEnum.fast) {
                result.fastInProgress = true
            }
        })
    }
}
