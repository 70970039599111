/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import {ApiData, ApiLinks, ApiMeta, ApiResponse, ApiTranslatedResponse, TranslatedApiData} from "../Models/ApiResponseModel"
import OxSpecsApi from "./OxSpecsApi"

/**
 * OxTranslatorCore
 *
 * Parseur de données en objets
 */
export default class OxTranslatorCore {
  // Ressources annexes
  private included: any[] = []
  // Ressources brutes
  private data
  // Metadonnées
  private meta: ApiMeta
  // Liens de la donnée
  private links: ApiLinks
  // Activation des spécifications de l'object
  private index = false

  /**
   * @inheritDoc
   */
  public constructor(data: ApiData|ApiData[], meta: ApiMeta, links: ApiLinks, included: any[] = [], indexSpecs = false) {
    this.meta = meta
    this.links = links

    included.forEach(
      (_inc) => {
        if (Array.isArray(_inc)) {
          this.included = included.concat(_inc)
        }
        else {
          this.included.push(_inc)
        }
      }
    )
    this.data = data
    this.index = indexSpecs
    return this
  }

  /**
   * Retour des données avec modification du format et et liaison avec les données annexes
   *
   * @return object|object[]
   */
  public async translateData(): Promise<ApiTranslatedResponse<any>> {
    if (this.index) {
      await this.indexSpecs()
    }
    return {
      data :
        Array.isArray(this.data) ?
        await Promise.all(
          this.data.map(
            async (_data) => {
              return this.translateObject(_data)
            }
          )
        ) :
        this.translateObject(this.data),
      meta: this.meta,
      links: this.links
    }
  }

  /**
   * Modification du format d'un objet donné et liaison avec ses données annexes
   *
   * @param data object Données d'un objet unique
   */
  public translateObject(data): TranslatedApiData {
    let ex = {}
    if (data && data.relationships) {
      Object.keys(data.relationships).map(
        (_relIndex) => {
          let _rel = data.relationships[_relIndex]
          if (!_rel.data || !_rel.data.type) {
            return
          }
          let _inc = this.extractInclude(_rel.data.type, _rel.data.id)
          if (!_inc) {
            return
          }
          ex["_" + _rel.data.type] = _inc
        }
      )
    }
    return Object.assign(
      {
        _type: data && data.type ? data.type : null,
        _id: data && data.id ? data.id : null,
        _links: data && data.links ? data.links : {}
      },
      data.attributes,
      ex
    )
  }

  /**
   * Récupération d'une donnée annexe depuis les données indéxées
   *
   * @param type string Type de ressource
   * @param id   string Identifiant de la ressource
   *
   * @return object
   */
  private extractInclude(type, id): any {
    let inc = this.included.filter(
      (_inc: {type: string; id: string}) => {
        return _inc.type === type && _inc.id === id
      }
    )
    return inc.length > 0 ? this.translateObject(inc[0]) : false
  }

  /**
   * Indexation  des spécifications d'objets
   */
  private async indexSpecs(): Promise<void> {
    if (!this.data || !this.data.length) {
      return
    }
    await this.indexSpec(Array.isArray(this.data) ? this.data[0] : this.data)
  }

  /**
   * Indexation des spécifications d'un objet donné
   * @param data
   */
  private async indexSpec(data): Promise<void> {
    if (!data.links || !data.links.schema) {
      return
    }
    await OxSpecsApi.setSpecsByLink(data.links.schema, data.type)
  }
}
