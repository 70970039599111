import VueFormulate from '@braid/vue-formulate'
import { FormBaseComponent } from '@internal-libraries/kheops-ui-lib'
import FormBaseComponentEmpty from './formComponent'

export default function(formulateInstance: typeof VueFormulate): void {
    formulateInstance.extend({
        components: {
            FormBaseComponent,
            FormBaseComponentEmpty,
        },
        library: {
            ClvrPatientAutocomplete: {
                classification: 'select',
                component: 'FormBaseComponent'
            },
            ChainingManagementContext: {
                classification: 'text',
                component: 'FormBaseComponentEmpty'
            },
            KhCheckbox: {
                classification: 'button',
                component: 'FormBaseComponent'
            },
            ChainingManagementServiceList: {
                classification: 'text',
                component: 'FormBaseComponent'
            },
            ClvrPrscPosologyFrequencySelector: {
                classification: 'text',
                component: 'FormBaseComponent'
            },
            ClvrPrscPosologyCustomized: {
                classification: 'text',
                component: 'FormBaseComponent'
            }
        }
    })
}
