import { Component } from 'vue-property-decorator'
import { Nullable } from '@internal-libraries/kheops-ui-lib'
import { KhSnackbarManager, SnackbarManager } from '@internal-libraries/kheops-ui-lib'
import Vue from 'vue'

@Component({
    components: {
        KhSnackbarManager,
    },
})
export default class KhVueAttachable extends Vue {
    public snackbarManager: Nullable<SnackbarManager> = null
}
