/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import OxParametersProvider from './OxParametersApi/OxParametersProvider'
import OxStoreCore from './OxStores/OxStoreCore'

/**
 * OxSpecsApi
 *
 * Gestionnaire des specs d'objet
 */
export default class OxSpecsApi {
  public static async setSpecs(classe: string, type: string, fieldsets : string[]|false = false) {
    if (!classe) {
      return false
    }
    let url = "schemas/models/" + classe
    if (fieldsets) {
      url += "?fieldsets="
      fieldsets.forEach(
        (_fieldset, _fieldsetIndex) => {
          url += ((_fieldsetIndex > 0) ? "%2C" : "") + _fieldset
        }
      )
    }
    return this.setSpecsByLink(url, type)
  }
  public static async setSpecsByLink(url: string, type: string) {
    const fieldsets = this.extractFieldsets(url)
    if (OxStoreCore.getters.hasSpecByFieldsets(type, fieldsets)) {
      return
    }
    let specs = await (new OxParametersProvider()).loadSpecs(url)
    let objectSpec = {}
    specs.forEach(
      (_spec) => {
        objectSpec[_spec.field] = {
          specs: _spec,
          field: _spec.field,
          object: type
        }
      }
    )
    OxStoreCore.commit('setSpec', {type: type, specs: objectSpec, fieldsets: fieldsets})
  }

  private static extractFieldsets(url: string): string[]{
    let match = url.match(/[\?\&]fieldsets=.*\&/g)
    if (!match) {
      match = url.match(/[\?\&]fieldsets=.*$/g)
    }
    if (!match) {
      match = ["default"]
    }

    const fieldset = match[0]
    return decodeURIComponent(fieldset.substr(fieldset.indexOf("=") + 1).replace("&", ""))
      .split(",")
  }

  public static get(type: string, field: string): any {
    return OxStoreCore.getters.spec(type, field)
  }
}
