var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _vm.context.type,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          attrs: {
            label: _vm.context.label,
            "error-messages": _vm.context.visibleValidationErrors,
          },
          on: { blur: _vm.context.blurHandler },
          model: {
            value: _vm.context.model,
            callback: function ($$v) {
              _vm.$set(_vm.context, "model", $$v)
            },
            expression: "context.model",
          },
        },
        "component",
        _vm.context.attributes,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }