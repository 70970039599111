import CodingInformationStoreGetters from './CodingInformationStoreGetters'
import CodingInformationStoreActions from './CodingInformationStoreActions'
import CodingInformationStoreMutations from './CodingInformationStoreMutations'
import CodingInformationStoreState from './CodingInformationStoreState'

export default {
    codingInformationStore: {
        namespaced: false,
        state: CodingInformationStoreState,
        getters: CodingInformationStoreGetters,
        mutations: CodingInformationStoreMutations,
        actions: CodingInformationStoreActions
    }
}
