var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-textarea", {
    attrs: {
      "append-icon": _vm.icon ? _vm.iconName : "",
      color: _vm.fieldColor,
      "background-color": _vm.fieldBG,
      dark: _vm.onPrimary,
      disabled: _vm.disabled,
      error: _vm.error,
      "error-messages": _vm.errorMessage,
      filled: "",
      "hide-details": "auto",
      hint: _vm.hint,
      loading: _vm.showLoading,
      label: _vm.labelComputed,
      "persistent-hint": _vm.persistentHint,
      rows: _vm.rows,
      success: _vm.success,
      value: _vm.mutatedValue,
    },
    on: { input: _vm.changeValue },
    scopedSlots: _vm._u([
      {
        key: "message",
        fn: function () {
          return [_vm._v("\n    " + _vm._s(_vm.message) + "\n  ")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }