/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

export default class OxThemeCore {
  public static primary50 = "#E8EAF6"
  public static primary100 = "#C5CAE9"
  public static primary200 = "#9FA8DA"
  public static primary300 = "#7986CB"
  public static primary400 = "#5C6BC0"
  public static primary = "#3F51B5"

  public static secondary = "#03A9F4"
  public static secondary200 = "#81D4FA"
  public static secondary50 = "#E1F5FE"

  public static blackMediumEmphasis = "rgba(0, 0, 0, 0.6)"
  public static blackHighEmphasis = "rgba(0, 0, 0, 0.87)"
  public static whiteMediumEmphasis = "rgba(255, 255, 255, 0.7)"
  public static whiteHighEmphasis = "rgba(255, 255, 255, 1)"

  public static backgroundWhite = "#FFFFFF"
  public static backgroundLight = "#FAFAFA"

  public static blueLight = "#2196F3"
  public static pinkLight = "#EC407A"

  public static successText = "#43a047"
  public static errorText = "#FF3100"

  public static grey = "#263238"
  public static grey300 = "#90A4AE"
}
