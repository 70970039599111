import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { ChainingUserApiAdapter } from '../../../chaining/vue/adapter/ChainingUserApiAdapter'
import { get } from 'lodash'
import { ChainingPatientServiceHistoryStatus } from '../../../chaining/vue/types/ChainingPatientService/ChainingPatientServiceHistoryStatus'
import { AllergyFoodPatientHistoryDto } from '../types/AllergyFoodPatientHistoryDto'
import AllergyFoodPatientHistory from '../types/AllergyFoodPatientHistory'
import ChainingUser from '../../../chaining/vue/classes/ChainingUser'
import { AllergyFoodPatientHistoryDetailsApiAdapter } from '../adapter/AllergyFoodPatientHistoryDetailsApiAdapter'
import CommonHelper from '../../../system/vue/Classes/CommonHelper'

export class AllergyFoodPatientHistoryApiAdapter implements ApiDataAdapter<AllergyFoodPatientHistory> {
    createClass(apiData: AllergyFoodPatientHistoryDto): AllergyFoodPatientHistory {
        let dateFormated = ''
        if (apiData.date) {
            dateFormated = CommonHelper.formatDate(apiData.date)
        }
        let fullNameUser = ''
        if (apiData.user) {
            const chainingUser = new ChainingUser(apiData.user.id, apiData.user.firstName, apiData.user.lastName, apiData.user.userName)
            fullNameUser = chainingUser.getFullName()
        }
        const chainingUserAdapter = new ChainingUserApiAdapter()
        const detailsAdapter = new AllergyFoodPatientHistoryDetailsApiAdapter()
        return new AllergyFoodPatientHistory(
            get(apiData, 'status', ChainingPatientServiceHistoryStatus.added) as ChainingPatientServiceHistoryStatus,
            chainingUserAdapter.createClass(get(apiData, 'user')),
            new Date(get(apiData, 'date')),
            detailsAdapter.createClass(get(apiData, 'details')),
            dateFormated,
            fullNameUser
        )
    }
}
