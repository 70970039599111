export interface Alert {
  key: number
  type: AlertType
  libelle: string
  delay: number
}

export enum AlertType {
  success = "success",
  info = "info",
  warning = "warning",
  error = "error"
}

export enum AlertDelay {
  none = 0,
  short = 2000,
  medium = 5000,
  long = 10000
}
