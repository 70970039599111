import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { get } from 'lodash'
import { StayDto } from '../../../dPplanningOp/vue/Type/StayDto'
import Stay from '../../../dPplanningOp/vue/Classes/Stay'
import { PatientAPIAdapter } from '../../../dPpatients/vue/Adapter/PatientAPIAdapter'
import { AllergyStayAPIAdapter } from '../../../dPpatients/vue/Adapter/AllergyStayAPIAdapter'

export class StRehaStayAPIAdapter implements ApiDataAdapter<Stay> {
    createClass(apiData: StayDto): Stay {
        const stay = new AllergyStayAPIAdapter().createClass(apiData)
        const refPatient = get(apiData, 'patient')
        if (refPatient) {
            stay.refPatient = new PatientAPIAdapter().createClass(refPatient)
        }

        return stay
    }
}
