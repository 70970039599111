var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    {
      ref: "quantityPickerForm",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitQuantityPickerForm.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "actionButtons" }, [
        _c(
          "div",
          { staticClass: "v-btn-toggle form-group" },
          [
            _c(
              "v-btn",
              {
                attrs: {
                  color: _vm.buttonColors,
                  elevation: "2",
                  small: "",
                  disabled: _vm.minusButtonDisabled,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.minus()
                  },
                },
              },
              [
                _c("ox-vicon", {
                  staticClass: "btnMinus",
                  attrs: { icon: "minus", alt: "minus" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-text-field", {
              staticClass: "quantity-input elevation-2 form-control pt-0",
              class: {
                quantityValueInvalid:
                  _vm.submitted &&
                  _vm.$v.inputQuantityValue.$invalid &&
                  _vm.$v.inputQuantityValue.$error,
              },
              attrs: {
                type: "number",
                disabled: _vm.inputTextDisabled,
                required: "",
              },
              on: {
                change: _vm.updateQuantityFromInput,
                input: function ($event) {
                  return _vm.$v.inputQuantityValue.$touch()
                },
                blur: function ($event) {
                  return _vm.$v.inputQuantityValue.$touch()
                },
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
              model: {
                value: _vm.inputQuantityValue,
                callback: function ($$v) {
                  _vm.inputQuantityValue = $$v
                },
                expression: "inputQuantityValue",
              },
            }),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: {
                  small: "",
                  color: _vm.buttonColors,
                  elevation: "2",
                  disabled: _vm.plusButtonDisabled,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.plus()
                  },
                },
              },
              [
                _c("ox-vicon", {
                  staticClass: "btnPlus",
                  attrs: { icon: "plus", alt: "plus" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }