export default {
    getDocumentClickEvents: (state) => {
        return state.document_click_id.map(
            (_id) => {
                return state.document_click[_id]
            }
        )
    },
    getDocumentScrollEvents: (state) => {
        return state.document_scroll_id.map(
            (_id) => {
                return state.document_scroll[_id]
            }
        )
    },
}
