import _, { isString } from 'lodash'
import { format } from 'date-fns'

export default class CommonHelper {
    public static compareTwoStrings(nameA: string, nameB: string): number {
        return _.camelCase(nameA).localeCompare(_.camelCase(nameB))
    }

    public static readonly DEFAULT_COLOR_CARD: string = '#e5e9ef'
    public static readonly MAX_SERVICE_NUMBER: number = 999

    public static formattedTodayDate(): string {
        return format(new Date(), 'yyyy-MM-dd')
    }

    public static formatDate(dateToFormat: Date | string, formatDate = 'dd-MM-yyyy'): string {
        return format(isString(dateToFormat) ? new Date(dateToFormat) : dateToFormat, formatDate)
    }

    public static formatHour(dateToFormat: string): string {
        return format(new Date(dateToFormat), 'HH:mm')
    }

    public static formatDateToTime(dateToFormat: Date | string): string {
        return format(isString(dateToFormat) ? new Date(dateToFormat) : dateToFormat, 'HH:mm:ss')
    }

    public static formatDateToDateString(dateToFormat: Date): string {
        return format(dateToFormat, 'dd.MM.yyyy')
    }

    public static formatDateTimeToDateTimeString(dateToFormat: Date): string {
        return format(dateToFormat, 'yyyy-MM-dd HH:mm:ss')
    }

    public static formatDateToHourString(dateToFormat: Date): string {
        return format(dateToFormat, 'HH:mm')
    }

    public static ascendantSort<T>(a: T, b: T): number {
        if (a === b) return 0
        return a > b ? 1 : -1
    }
}
