import Vue from 'vue'
import ChainingHelper from '../../../chaining/vue/classes/ChainingHelper'
import { allergyCategoryEnum } from '../../../dPpatients/vue/Enum/allergyCategoryEnum'
import AllergyHelper from '../classes/AllergyHelper'

export default {
    saveCodingInformation: (state, codingInformation) => {
        state.codingInformation = codingInformation
    },
    emptyAllergiesStoreByKey: (state, storeKey) => {
        Vue.set(state.allergiesDataByKey, storeKey, {
            allergiesPatient: [],
            aversionsPatient: [],
            intolerancesPatient: [],
            allergiesImmunePatient: [],
            reactionsPatient: [],
            allergiesAversionLinkedPatient: [],
            allergiesIntoleranceLinkedPatient: [],
            allergiesFoodRefAllergenPatient: [],
        })
    },
    saveAllergiesPatient: (state, args) => {
        state.allergiesDataByKey[args.storeKey].allergiesPatient = args.allergies._allergy
        state.allergiesDataByKey[args.storeKey].intolerancesPatient = args.allergies._intolerance
        state.allergiesDataByKey[args.storeKey].aversionsPatient = args.allergies._aversion
        state.allergiesDataByKey[args.storeKey].allergiesImmunePatient = args.allergies._immune
    },
    addAllergyFoodPatient: (state, args) => {
        let keyStore = ''
        keyStore = AllergyHelper.getStoreKey(args.allergiesPatient.refCategoryId)

        const allergyIndex = state.allergiesDataByKey[args.storeKey][keyStore].findIndex((item) => item.id === args.allergiesPatient.id)
        state.allergiesDataByKey[args.storeKey][keyStore].splice(allergyIndex, 1, args.allergiesPatient)
    },
    saveReactionsPatient: (state, args) => {
        state.allergiesDataByKey[args.storeKey].reactionsPatient = args.reactionsPatient
    },
    saveAllergiesRefAllergenPatient: (state, args) => {
        state.allergiesDataByKey[args.storeKey].allergiesAversionLinkedPatient = args.allergiesAversionLinkedPatient
        state.allergiesDataByKey[args.storeKey].allergiesIntoleranceLinkedPatient = args.allergiesIntoleranceLinkedPatient
        state.allergiesDataByKey[args.storeKey].allergiesFoodRefAllergenPatient = args.allergiesFoodRefAllergenPatient
    },
    addAllergyFoodRefAllergenPatient: (state, args) => {
        state.allergiesDataByKey[args.storeKey].allergiesFoodRefAllergenPatient.push(args.allergiesFoodRefAllergenPatient)
    },
    deleteAllergyFoodPatient: (state, args) => {
        let keyStore = ''
        keyStore = AllergyHelper.getStoreKey(args.allergy.refCategoryId)
        const allergyIndex = state.allergiesDataByKey[args.storeKey][keyStore].findIndex((item) => item.id === args.allergy.id)
        state.allergiesDataByKey[args.storeKey][keyStore].splice(allergyIndex, 1)
    },
    createAllergyFoodPatient: (state, args) => {
        if (args.allergy.dateStart) {
            args.allergy.dateStartFormated = ChainingHelper.formatDate(args.allergy.dateStart)
        }
        if (args.allergy.refReaction && args.allergy.refReaction._refLinkedReaction && args.allergy.refReaction._refLinkedReaction.id) {
            args.allergy.refReactionId = Number(args.allergy.refReaction._refLinkedReaction.id)
        }

        switch (args.allergy.refCategoryId) {
            case allergyCategoryEnum.allergy:
                state.allergiesDataByKey[args.storeKey].allergiesPatient.push(args.allergy)
                break
            case allergyCategoryEnum.intolerance:
                state.allergiesDataByKey[args.storeKey].intolerancesPatient.push(args.allergy)
                break
            case allergyCategoryEnum.aversion:
                state.allergiesDataByKey[args.storeKey].aversionsPatient.push(args.allergy)
                break
            default:
                state.allergiesDataByKey[args.storeKey].allergiesImmunePatient.push(args.allergy)
        }
    },
}
