/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import {Component} from 'vue-property-decorator'
import OxVue from "../OxVue"
import {Alert, AlertDelay, AlertType} from "./OxAlertsModel"
import OxStoreCore from "../OxStores/OxStoreCore"
import OxButton from "../../Inputs/OxButton/OxButton"

/**
 * OxAlerts
 */
@Component({components: {OxButton}})
export default class OxAlerts extends OxVue {
  private hiddenAlerts: number[] = []

  private get alerts(): Alert[] {
    return OxStoreCore.getters.getAlerts.map(
      (alert: Alert) => {
        return Object.assign(
          {
            show: !this.hiddenAlerts.includes(alert.key)
          },
          alert
        )
      }
    )
  }

  private alertColor(alert: Alert): string {
    return {
      success: "green",
      info: "blue",
      warning: "orange",
      error: "red"
    }[alert.type]
  }

  public static addAlert(libelle: string, type: AlertType, delay: AlertDelay = AlertDelay.none): void {
    let key = Math.ceil(Math.random() * Math.pow(10, 16))
    OxStoreCore.commit("addAlert", { libelle: libelle, type: type, delay: delay, key: key})
  }

  public static addInfo(libelle: string, delay: AlertDelay = AlertDelay.none): void {
    OxAlerts.addAlert(libelle, AlertType.info, delay)
  }

  public static addWarning(libelle: string, delay: AlertDelay = AlertDelay.none): void {
    OxAlerts.addAlert(libelle, AlertType.warning, delay)
  }

  public static addError(libelle: string, delay: AlertDelay = AlertDelay.none): void {
    OxAlerts.addAlert(libelle, AlertType.error, delay)
  }

  public static addSuccess(libelle: string, delay: AlertDelay = AlertDelay.none): void {
    OxAlerts.addAlert(libelle, AlertType.success, delay)
  }

  private removeAlert(key: number): void {
    this.hiddenAlerts.push(key)
    setTimeout(
      () => {
        OxStoreCore.commit("removeAlert", key)
      },
      2000
    )
  }
}
