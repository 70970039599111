import ClvrEventsStoreState from './ClvrEventsStoreState'
import ClvrEventsStoreGetters from './ClvrEventsStoreGetters'
import ClvrEventsStoreMutations from './ClvrEventsStoreMutations'
import ClvrEventsStoreActions from './ClvrEventsStoreActions'

export default {
    events: {
        namespaced: false,
        state: ClvrEventsStoreState,
        getters: ClvrEventsStoreGetters,
        mutations: ClvrEventsStoreMutations,
        actions: ClvrEventsStoreActions
    }
}
