/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import axios from 'axios'
import OxVueApi from "./OxVueApi"
import OxTranslatorCore from "./OxTranslatorCore"
import {
    ApiResponse, ApiTranslatedEmptyResponse,
    ApiTranslatedResponse,
    ApiTranslatedResponseData,
    TranslatedApiData
} from "../Models/ApiResponseModel"
import OxDate from "../Inputs/OxDate/OxDate"
import OxStoreCore from "./OxStores/OxStoreCore"
import OxAlerts from "./OxAlerts/OxAlerts"
import {AlertType} from "./OxAlerts/OxAlertsModel"
import OxLoading from "./OxLoading/OxLoading"

/**
 * OxProviderCore
 *
 * Provider de données?
 */
export default class OxProviderCore {
  protected useRawUrl = false
  protected params: object = {}
  protected consecutiveFails = 0
  protected secondChance = false
  protected indexSpecs = false

  /**
   * Récupération de données obsolète : modèle + script
   *
   * @param module
   * @param script
   * @param params
   */
  public static async get(module: string, script: string, params: {}) : Promise<ApiResponse|false>{
    try {
      return (
        await axios.get(
          "./?m=" + module + "&raw=" + script,
          {
            params: params
          }
          ) as {data: ApiResponse}
        ).data
    } catch {
      OxProviderCore.warn()
    }
    return false
  }

  /**
   * Récupération de données depuis le fournisseur API de l'application
   *
   * @param url    Complément d'url (ou complète) à atteindre
   * @param params Paramètres supplémentaires
   * @param params Paramètres supplémentaires
   *
   * @return object
   */
  public async getApi<T extends ApiTranslatedResponseData>(url = "", params = {}, useCache = false, useSecondChance = false, indexSpecs = true): Promise<ApiTranslatedResponse<T>> {
    let response
    this.parseParams(params)
    this.secondChance = useSecondChance
    this.indexSpecs = indexSpecs

    if (useCache) {
      response = this.getFromCache(url)
      if (response) {
        return response
      }
    }
    response = this.callApi(
      async (url) => {
        return await axios.get(
          url,
          {
            params: this.params
          }
        )
      },
      url
    )
    if (useCache) {
      this.setToCache(url, response)
    }
    return response
  }

  public async postApi<T extends ApiTranslatedResponseData>(url = "", params?: {}): Promise<ApiTranslatedResponse<T>|ApiTranslatedEmptyResponse> {
    this.parseParams(params)
    return this.callApi<T>(
      async (url) => {
        return await axios.post(url, this.params)
      },
      url
    )
  }

  private getCacheKey(url: string): string {
    return url + JSON.stringify(this.params)
  }

  private setToCache(url: string, response: any): void {
    return OxStoreCore.commit('setApiCache', {key: this.getCacheKey(url), value: response})
  }

  private getFromCache(url: string): any {
    return OxStoreCore.getters.getApiCache(this.getCacheKey(url))
  }

  private get emptyResponse(): ApiTranslatedResponse<TranslatedApiData[]> {
    return {
      data: [],
      links: {},
      meta: {},
      status: 401
    }
  }

  private async callApi<T extends ApiTranslatedResponseData>(promise: Function, url = ""): Promise<ApiTranslatedResponse<T>|ApiTranslatedEmptyResponse> {
    const baseUrl = await OxVueApi.getBaseUrl()
    if (url.indexOf(baseUrl) === -1 && !this.useRawUrl) {
      url = baseUrl + url
    }
    try {
      const response = ((await Promise.resolve(promise(url))) as unknown as { data: ApiResponse, status: number })
      const apiResponse = response.data
      this.consecutiveFails = 0
      if (apiResponse.errors) {
        OxAlerts.addError(apiResponse.errors.message)
        OxLoading.unloadAll()
        return this.emptyResponse
      }
      return Object.assign(
        {
          status: response.status
        },
        await (new OxTranslatorCore(apiResponse.data, apiResponse.meta, apiResponse.links, apiResponse.included, false))
          .translateData()
      )
    } catch (e) {
      this.consecutiveFails++
      if (this.secondChance && this.consecutiveFails < 2) {
        return await this.callApi<T>(promise, url)
      }
      OxProviderCore.warn(e)
      OxLoading.unloadAll()
    }
    return this.emptyResponse
  }

  /**
   *
   * @param url
   * @param params
   */
  public async getAutocomplete(filter?: string): Promise<any[]> {
    return []
  }

  /**
   *
   * @param url
   * @param params
   */
  public async getAutocompleteById(filter?: number): Promise<any[]> {
    return []
  }

  /**
   * Récupération de données depuis le fournisseur API de l'application et translation du retour en objets
   *
   * @param url    Complément d'url (ou complète) à atteindre
   * @param params Paramètres supplémentaires
   *
   * @return object
   */
  // public async getApiThenTranslate(url = "", params?: {}): Promise<any> {
  //   let data = (await (new OxProviderCore()).getApi(url, params) as ApiResponse)
  //   return new OxTranslatorCore(data.data, data.meta, data.links, data.included).translateData()
  // }

  /**
   * Retours d'erreurs rencontrés lors d'échanges du provider de données
   *
   * @param e
   */
  public static warn(e ?): void {
    console.warn("Error while trying to use a provider")
    console.warn(e)
  }

  private parseParams(params:any = {}): void {
    if (!params || !Object.keys(params).length) {
      return
    }
    Object.keys(params).forEach(
      (_key) => {
        const _value = params[_key]
        if (_value === null || typeof(_value) === "undefined") {
          delete(params[_key])
        }
        else if (typeof(_value) === "boolean") {
          params[_key] = _value ? 1 : 0
        }
        else if (typeof(_value) === "string") {
          if (_value.length === 16 && OxDate.isDate(_value)) {
            params[_key] = _value + ":00"
          }
        }
      }
    )
    this.params = Object.assign(
      this.params,
      params
    )
  }
}
