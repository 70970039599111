export default {
    url: (state) => {
        return state.baseUrl
    },
    rooturl: (state) => {
        return state.rootUrl
    },
    loading: (state) => {
        return state.loadings.length > 0
    },
}
