import Vue from 'vue'
import { CodingInformationApiAdapter } from '../adapter/CodingInformationApiAdapter'
import { CodingInformation } from '../classes/CodingInformation'
import { AllergyStore } from '../types/AllergyStore'
import Allergy from '../classes/Allergy'
import { AllergyAPIAdapter } from '../adapter/AllergyApiAdapter'
import { AllergyRefReactionAPIAdapter } from '../../../dPpatients/vue/Adapter/AllergyRefReactionApiAdapter'
import AllergyRefReaction from '../../../dPpatients/vue/Classes/AllergyRefReaction'
import { AllergyReactionStore } from '../../../dPpatients/vue/Type/AllergyReactionStore'
import { AllergyAllergenStore } from '../../../dPpatients/vue/Type/AllergyAllergenStore'
import AllergyRefAllergen from '../../../dPpatients/vue/Classes/AllergyRefAllergen'
import { AllergyRefAllergenAPIAdapter } from '../../../dPpatients/vue/Adapter/AllergyRefAllergenApiAdapter'
import { tap } from 'rxjs/operators'
import { allergyCategoryEnum } from '../../../dPpatients/vue/Enum/allergyCategoryEnum'
import { AllergiesPatient } from '../types/AllergiesPatient'
import CommonHelper from '../../../system/vue/Classes/CommonHelper'
import { JsonApiResponseAdapter, KhDate } from '@internal-libraries/kheops-ui-lib'
import Stay from '../../../dPplanningOp/vue/Classes/Stay'
import { StRehaStayAPIAdapter } from '../../../soins/vue/adapter/StRehaStayAPIAdapter'

export default {
    loadCodingInformation({ commit }, id): Promise<CodingInformation> {
        const apiCall = Vue.prototype.$http.get('api/v1/ssr/codingview/' + id, new CodingInformationApiAdapter())
        apiCall.subscribe((response) => {
            const result = response.getManyData()
            commit('saveCodingInformation', result)
        })
        return apiCall.toPromise()
    },
    loadAllergiesPatient(data, args: AllergyStore): Promise<Allergy[]> {
        const url = 'api/v1/allergy/allergy?populate=refLinkedObject&is_active=0,1&patient_id=' + args.parameters.patient_id
        const apiCall = Vue.prototype.$http.get(url, new AllergyAPIAdapter())
        const allergies: AllergiesPatient = {}
        allergies._allergy = []
        allergies._intolerance = []
        allergies._aversion = []
        allergies._immune = []
        apiCall.subscribe((response) => {
            const result = response.getManyData()
            if (result) {
                result.forEach((allergyPatient: Allergy, key) => {
                    if (allergyPatient.dateStart) {
                        allergyPatient.dateStartFormated = new KhDate(allergyPatient.dateStart).format('MMMM yyyy', Vue.$i18n.dateLocale)
                    }

                    allergyPatient.refsStay?.forEach((stay) => {
                        if (!allergyPatient.stayIds) {
                            allergyPatient.stayIds = []
                        }
                        if (stay._refLinkedStays && stay._refLinkedStays.id && Number(args.parameters.sejour_id) === Number(stay._refLinkedStays.id)) {
                            allergyPatient.stayIds.push(Number(stay._refLinkedStays.id))
                        }
                    })

                    if (allergyPatient.refReaction?._refLinkedReaction?.id) {
                        allergyPatient.refReactionId = Number(allergyPatient.refReaction._refLinkedReaction.id)
                    }

                    allergyPatient.openHistoryService()
                    if (!allergyPatient.isImmune) {
                        switch (allergyPatient.refCategoryId) {
                            case allergyCategoryEnum.intolerance:
                                allergies._intolerance?.push(allergyPatient)
                                break
                            case allergyCategoryEnum.aversion:
                                allergies._aversion?.push(allergyPatient)
                                break
                            default:
                                allergies._allergy?.push(allergyPatient)
                        }
                    } else {
                        allergies._immune?.push(allergyPatient)
                    }
                })

                data.commit('saveAllergiesPatient', {
                    allergies: allergies,
                    storeKey: args.storeKey,
                })
            }
        })
        return apiCall.toPromise()
    },
    addAllergyFoodPatient(data, args: AllergyStore): Promise<Allergy> {
        const id = args.parameters.id ? args.parameters.id : args.parameters.allergy_id
        const apiCall = Vue.prototype.$http.put(
            'api/v1/allergy/allergy/' + id + '?populate=refLinkedObject&is_active=0,1&patient_id=' + args.parameters.patient_id,
            new AllergyAPIAdapter(),
            args.parameters
        )
        apiCall.subscribe(response => {
            const result = response.getOneData()
            if (result.dateStart) {
                result.dateStartFormated = CommonHelper.formatDate(result.dateStart)
            }

            result.refsStay?.forEach((stay) => {
                if (!result.stayIds) {
                    result.stayIds = []
                }
                if (stay._refLinkedStays && stay._refLinkedStays.id && Number(args.parameters.sejour_id) === Number(stay._refLinkedStays.id)) {
                    result.stayIds.push(Number(stay._refLinkedStays.id))
                }
            })
            data.commit('addAllergyFoodPatient', {
                allergiesPatient: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadReactionsPatient(data, args: AllergyReactionStore): Promise<AllergyRefReaction[]> {
        const url = 'api/v1/allergy/allergyRefReaction'
        const apiCall = Vue.prototype.$http.get(url, new AllergyRefReactionAPIAdapter())
        apiCall.subscribe(response => {
            const result = response.getManyData()
            result.forEach((reaction) => {
                reaction.labelForMenu = '[' + reaction.stage + '] ' + reaction.name
            })
            data.commit('saveReactionsPatient', {
                reactionsPatient: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    loadAllergiesRefAllergenPatient(data, args: AllergyAllergenStore): Promise<AllergyRefAllergen[]> {
        const url = 'api/v1/allergy/allergyRefAllergen?populate=refCategory&is_validate=1'
        const apiCall = Vue.prototype.$http.get(url, new AllergyRefAllergenAPIAdapter())
        apiCall.subscribe(response => {
            const result = response.getManyData()
            const allergiesFoodLinkedPatient: AllergyRefAllergen[] = []
            const allergiesIntoleranceLinkedPatient: AllergyRefAllergen[] = []
            const allergiesAversionLinkedPatient: AllergyRefAllergen[] = []
            result.forEach((allergyRefAllergen) => {
                if (allergyRefAllergen.refLinkAllergen) {
                    allergyRefAllergen.refLinkAllergen.forEach((category) => {
                        if (category.id && category.isActive && category._refCategory) {
                            switch (Number(category._refCategory.id)) {
                                case allergyCategoryEnum.allergy:
                                    allergiesFoodLinkedPatient.push(allergyRefAllergen)
                                    break
                                case allergyCategoryEnum.intolerance:
                                    allergiesIntoleranceLinkedPatient.push(allergyRefAllergen)
                                    break
                                default:
                                    allergiesAversionLinkedPatient.push(allergyRefAllergen)
                            }
                        }
                    })
                }
            })
            data.commit('saveAllergiesRefAllergenPatient', {
                allergiesFoodRefAllergenPatient: allergiesFoodLinkedPatient,
                allergiesIntoleranceLinkedPatient: allergiesIntoleranceLinkedPatient,
                allergiesAversionLinkedPatient: allergiesAversionLinkedPatient,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    addAllergyRefAllergenPatient({ commit }, args: AllergyAllergenStore): Promise<AllergyRefAllergen> {
        return Vue.prototype.$http
            .post('api/v1/allergy/allergyRefAllergen?populate=refCategory', new AllergyRefAllergenAPIAdapter(), args.parameters)
            .pipe(
                tap((addedAllergy: JsonApiResponseAdapter<AllergyRefAllergen>) =>
                    commit('addAllergyFoodRefAllergenPatient', { allergiesFoodRefAllergenPatient: addedAllergy.getManyData(), storeKey: args.storeKey })
                )
            )
            .toPromise()
    },
    updateLinkedStay(data, args: AllergyStore): Promise<Allergy> {
        const id = args.parameters.id ? args.parameters.id : args.parameters.allergy_id
        const apiCall = Vue.prototype.$http.put(
            'api/v1/allergy/allergy/update_linked_stay/' + id + '?populate=refLinkedObject&is_active=0,1&patient_id=' + args.parameters.patient_id,
            new AllergyAPIAdapter(),
            args.parameters
        )
        apiCall.subscribe(response => {
            const result = response.getOneData()

            if (result.dateStart) {
                result.dateStartFormated = CommonHelper.formatDate(result.dateStart)
            }

            result.refsStay?.forEach((stay) => {
                if (!result.stayIds) {
                    result.stayIds = []
                }
                if (stay._refLinkedStays && stay._refLinkedStays.id && Number(args.parameters.sejour_id) === Number(stay._refLinkedStays.id)) {
                    result.stayIds.push(Number(stay._refLinkedStays.id))
                }
            })
            data.commit('addAllergyFoodPatient', {
                allergiesPatient: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    deleteAllergyFoodPatient(data, args: AllergyStore): Promise<Allergy> {
        const apiCall = Vue.prototype.$http.delete(
            'api/v1/allergy/allergy/' + args.parameters.id + '?populate=refLinkedObject&is_active=0,1&patient_id=' + args.parameters.patientId,
            new AllergyAPIAdapter(),
            args.parameters
        )
        apiCall.subscribe((response) => {
            const result = response.getOneData()
            data.commit('deleteAllergyFoodPatient', {
                allergy: result,
                storeKey: args.storeKey,
            })
        })
        return apiCall.toPromise()
    },
    createAllergyFoodPatient({ commit }, args: AllergyStore): Promise<Allergy> {
        return Vue.prototype.$http
            .post(
                'api/v1/allergy/allergy?populate=refLinkedObject&is_active=0,1&patient_id=' + args.parameters.patient_id,
                new AllergyAPIAdapter(),
                args.parameters
            )
            .pipe(
                tap((addedAllergy: JsonApiResponseAdapter<Allergy>) =>
                    commit('createAllergyFoodPatient', { allergy: addedAllergy.getManyData(), storeKey: args.storeKey })
                )
            )
            .toPromise()
    },
    loadStayWithPatient(data, stayId: string): Promise<Stay> {
        return Vue.prototype.$http
            .get(
                'api/v1/planning/stay/' + stayId + '?fieldsets=all',
                new StRehaStayAPIAdapter()
            )
            .toPromise()
    }
}
