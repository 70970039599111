export default {
    setSpec: (state, object: { type: string, specs: any, fieldsets: string[] }) => {
        state.specs[object.type] = object.specs
        object.fieldsets.forEach(
            (_fieldset) => {
                state.saved_specs.push(object.type + "-" + _fieldset)
            }
        )
    },
}
