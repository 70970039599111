import {Alert} from '../../../OxAlerts'

export default {
    addAlert: (state, alert: Alert) => {
        state.alerts.push(alert)
        if (alert.delay) {
            setTimeout(
                () => {
                    state.alerts.splice(
                        state.alerts.findIndex(a => a.key === alert.key),
                        1
                    )
                },
                alert.delay
            )
        }
    },
    removeAlert: (state, key: number) => {
        const index = state.alerts.findIndex(alert => alert.key === key)
        if (index === -1) {
            return
        }
        state.alerts.splice(index, 1)
    }
}
