export default {
    setDocumentClick: (state, func: { id: string, func: Function }) => {
        state.document_click[func.id] = func.func
        state.document_click_id.push(func.id)
    },
    removeDocumentClick: (state, id: string) => {
        if (typeof (state.document_click[id]) === "undefined") {
            return
        }
        state.document_click_id.splice(state.document_click_id.indexOf(id), 1)
        delete (state.document_click[id])
    },
    setDocumentScroll: (state, func: { id: string, func: Function }) => {
        state.document_scroll[func.id] = func.func
        state.document_scroll_id.push(func.id)
    },
    removeDocumentScroll: (state, id: string) => {
        if (typeof (state.document_scroll[id]) === "undefined") {
            return
        }
        state.document_scroll_id.splice(state.document_scroll_id.indexOf(id), 1)
        delete (state.document_scroll[id])
    }
}
