import { Rule } from '../types/Rule'
import { Period } from '../components/StReha/classes/Period'

export class CodingInformation {
    constructor(
        public title: string,
        public requiredPeriodMinutes: number,
        public isWeeklyAverage: boolean,
        public rules: Rule,
        public stayPeriod: Period,
        public periods: Period[]
    ) {}
}
