export default {
    spec: (state) => (objectType, objectField) => {
        return state.specs[objectType] && state.specs[objectType][objectField] ? state.specs[objectType][objectField] : false
    },
    // hasSpecByType: (state) => (objectType) => { return objectType },
    hasSpecByFieldset: (state) => (objectType, objectFieldset) => {
        if (state.saved_specs.indexOf(objectType + "-" + objectFieldset) === -1) {
            return false
        }
        return true
    },
    hasSpecByFieldsets: (state) => (objectType, objectFieldsets) => {
        for (let i = 0; i < objectFieldsets.length; i++) {
            if (state.saved_specs.indexOf(objectType + "-" + objectFieldsets[i]) === -1) {
                return false
            }
        }
        return true
    },
}
