import { AllergyFoodPatientFormData } from '../../../soins/vue/types/AllergyFoodPatientFormData'
import AllergyRefAllergen from '../../../dPpatients/vue/Classes/AllergyRefAllergen'
import { AllergyRefCategory } from '../../../dPpatients/vue/Classes/AllergyRefCategory'
import { AllergyLinkReaction } from '../../../dPpatients/vue/Classes/AllergyLinkReaction'
import { AllergyLinkSejour } from '../../../dPpatients/vue/Classes/AllergyLinkSejour'
import { AllergyLinkReactionForm } from '../../../dPpatients/vue/Type/AllergyLinkReactionForm'
import ChainingPatientServiceHistory from '../../../chaining/vue/classes/ChainingPatientServiceHistory'
import Vue from 'vue'
import { AllergyFoodPatientHistoryApiAdapter } from '../adapter/AllergyFoodPatientHistoryApiAdapter'
import AllergyFoodPatientHistory from '../types/AllergyFoodPatientHistory'

export default class Allergy {
    public sejourId?: number
    public delay?: string
    public dateStart?: string
    public dateEnd?: string
    public source?: string
    public sourceOther?: string
    public stageMax?: number
    public isImmune?: boolean
    public isValid?: boolean
    public isActive?: boolean
    public refTypeId?: number
    public refAllergen?: AllergyRefAllergen
    public refCategory?: AllergyRefCategory
    public refReaction?: AllergyLinkReaction
    public refsStay?: AllergyLinkSejour[]
    public stayIds?: number[]
    public refReactionId?: number
    public refLinkedStayId?: number
    public dateStartFormated?: string
    public _history?: ChainingPatientServiceHistory[]

    public constructor(public id?: number, public refAllergenId?: number, public refCategoryId?: number, public patientId?: number) {}

    public formateItemToFormData(sejourId): AllergyFoodPatientFormData {
        let refReactionFormated: AllergyLinkReactionForm = {}
        if (this.refReaction) {
            refReactionFormated = this.refReaction.formateItemToFormData()
        }
        return {
            allergy_id: this.id,
            ref_type_id: this.refTypeId,
            ref_category_id: this.refCategoryId,
            patient_id: this.patientId,
            sejour_id: sejourId,
            delay: this.delay,
            date_start: this.dateStart,
            date_end: this.dateEnd,
            source: this.source,
            source_other: this.sourceOther,
            stage_max: this.stageMax,
            is_immune: this.isImmune,
            is_valid: this.isValid,
            is_active: this.isActive,
            ref_allergen_id: this.refAllergenId,
            ref_reaction_id: this.refReactionId,
            _ref_reaction: refReactionFormated,
            _refLinkedStayId: this.refLinkedStayId,
        }
    }

    public openHistoryService(): void {
        if (this.id) {
            Vue.prototype.$http
                .get(
                    'api/v1/allergy/allergy/' + this.id + '/history' + '?populate=refLinkedObject&is_active=0,1&patient_id=' + this.patientId,
                    new AllergyFoodPatientHistoryApiAdapter()
                )
                .subscribe((data: AllergyFoodPatientHistory[]) => {
                    this._history = data.sort((a, b) => a.date.getTime() - b.date.getTime())
                })
        }
    }
}
