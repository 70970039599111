import { get } from 'lodash'
import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import { AllergyDto } from '../types/AllergyDto'
import Allergy from '../classes/Allergy'
import { AllergyRefAllergenAPIAdapter } from '../../../dPpatients/vue/Adapter/AllergyRefAllergenApiAdapter'
import { AllergyRefCategoryAPIAdapter } from '../../../dPpatients/vue/Adapter/AllergyRefCategoryAPIAdapter'
import { AllergyLinkSejour } from '../../../dPpatients/vue/Classes/AllergyLinkSejour'
import { AllergyLinkSejourAPIAdapter } from '../../../dPpatients/vue/Adapter/AllergyLinkSejourAPIAdapter'
import { AllergyLinkReactionAPIAdapter } from '../../../dPpatients/vue/Adapter/AllergyLinkReactionAPIAdapter'
import { Undefinable } from '@internal-libraries/kheops-ui-lib'

export class AllergyAPIAdapter implements ApiDataAdapter<Allergy> {
    public createClass(apiData: AllergyDto): Allergy {
        const refAllergen = get(apiData, '_refAllergen')
        const refCategory = get(apiData, '_refCategory')
        const refReaction = get(apiData, '_refReaction')
        const refsStay = get(apiData, '_refsStay')
        let stays: AllergyLinkSejour[] = []
        let refReactionId: Undefinable<number>
        if (refsStay) {
            if (Array.isArray(refsStay)) {
                stays = refsStay.map(stay => new AllergyLinkSejourAPIAdapter().createClass(stay))
            } else {
                stays = [new AllergyLinkSejourAPIAdapter().createClass(refsStay)]
            }
        }

        if (refReaction?._refLinkedReaction?.id) {
            refReactionId = refReaction._refLinkedReaction.id
        }

        const newAllergy = new Allergy(
            Number(get(apiData, 'id')),
            Number(get(apiData, 'ref_allergen_id')),
            Number(get(apiData, 'ref_category_id')),
            Number(get(apiData, 'patient_id')),
        )

        newAllergy.sejourId = get(apiData, 'sejour_id')
        newAllergy.delay = get(apiData, 'delay')
        newAllergy.dateStart = get(apiData, 'date_start')
        newAllergy.dateEnd = get(apiData, 'date_end')
        newAllergy.source = get(apiData, 'source')
        newAllergy.sourceOther = get(apiData, 'source_other')
        newAllergy.stageMax = get(apiData, 'stage_max')
        newAllergy.isImmune = get(apiData, 'is_immune')
        newAllergy.isValid = get(apiData, 'is_valid')
        newAllergy.isActive = get(apiData, 'is_active')
        newAllergy.refTypeId = Number(get(apiData, 'ref_type_id'))
        newAllergy.refAllergen = refAllergen ? new AllergyRefAllergenAPIAdapter().createClass(refAllergen) : undefined
        newAllergy.refCategory = refCategory ? new AllergyRefCategoryAPIAdapter().createClass(refCategory) : undefined
        newAllergy.refReaction = refReaction ? new AllergyLinkReactionAPIAdapter().createClass(refReaction) : undefined
        newAllergy.refsStay = stays
        newAllergy.stayIds = get(apiData, 'stayIds')
        newAllergy.refReactionId = refReactionId ? Number(refReactionId) : undefined

        return newAllergy
    }
}
