import { SysFieldValueDto } from '../../../system/vue/Type/SysFieldValueDto'

export class SysFieldValue {
    constructor(
        public value: string,
    ) {}

    public formateItemToFormData(): SysFieldValueDto {
        return {
            value: this.value,
        }
    }
}

