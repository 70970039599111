/**
 * @author  Kheops Technologies SA <devops@kheops.ch>
 * @copyright Kheops Technologies SA
 */
import { Snack, SnackbarManager, SnackColor, SnackDelay } from '@internal-libraries/kheops-ui-lib'
import { Component, InjectReactive, Vue } from 'vue-property-decorator'
import { ClvrHttpClientError } from '../Type'

type SnackType = {
    message: string
    color: SnackColor
    delay: SnackDelay
}

@Component({
    inheritAttrs: false,
})
export default class ClvrComponent extends Vue {
    @InjectReactive() protected snackbarManager!: SnackbarManager

    protected addErrorSnack(message = 'clevehr:common-error_occured', onInstance = false): void {
        this.addSnack({ message: message, color: SnackColor.error, delay: SnackDelay.medium }, onInstance)
    }

    protected addWarningSnack(message = '', onInstance = false): void {
        this.addSnack({ message: message, color: SnackColor.warning, delay: SnackDelay.medium }, onInstance)
    }

    protected addSuccessSnack(message = '', onInstance = false): void {
        this.addSnack({ message: message, color: SnackColor.success, delay: SnackDelay.medium }, onInstance)
    }

    // @TODO in ui-lib, add snack type to only have one function hook with object as parameter
    private addSnack(snackData: SnackType, onInstance = false): void {
        const attachedSnackbarManager = window.attachedVue?.snackbarManager
        const snackbarManagerUsed =
            onInstance && attachedSnackbarManager ? attachedSnackbarManager : this.snackbarManager

        snackbarManagerUsed.addSnack(new Snack(this.$t(snackData.message), snackData.color, snackData.delay))
    }

    protected getApiErrorMsg(error: ClvrHttpClientError): string {
        return 'response' in error ? error.response.data.errors.message : error.message
    }

    protected manageApiError(error: ClvrHttpClientError): void {
        this.addErrorSnack(this.getApiErrorMsg(error), true)
    }
}
