/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import {Component, Prop, Watch} from 'vue-property-decorator'
import OxVue from "../../Core/OxVue"
import { OxFieldListElement } from "../../Models/OxFieldModel"
import OxThemeCore from "../../Core/OxThemeCore"

/**
 * OxField
 *
 * Composant-modèle pour les composants de champ
 */
@Component
export default class OxFieldCore extends OxVue {
  // Label du champ
  @Prop({default: ""})
  protected label!: string

  @Prop({default: ""})
  protected title!: string

  @Prop({default: ""})
  protected value!: any

  @Prop({default: false})
  protected disabled!: boolean

  @Prop({default: false})
  protected required!: boolean

  @Prop({default: false})
  protected notNull!: boolean

  @Prop({default: () => { return [] }})
  protected list!: OxFieldListElement[]|string[]

  @Prop({default: ""})
  protected message!: string

  @Prop({default: 'info'})
  protected state!: 'info'|'error'|'error?'|'success'

  @Prop({default: ''})
  protected icon!: string

  @Prop({default: false})
  protected onPrimary!: boolean

  @Prop({default: false})
  protected showLoading!: boolean

  private fieldId!: string

  protected mutatedValue: any = ""


  protected created(): void {
    this.fieldId = Math.ceil(Math.random() * Math.pow(10, 10)).toString()
  }

  protected mounted(): void {
    this.updateMutatedValue()
  }

  protected change(value: any): void {
    this.$emit("change", value)
  }

  protected get fieldClasses() {
    return {
      "labelled": this.label !== "",
      "not-null": this.notNull
    }
  }

  public get viewList(): OxFieldListElement[] {
    return (this.list as OxFieldListElement[]).map(
      (_el: OxFieldListElement|string) => {
        return typeof(_el) === "string" ? { name: _el, value: _el} : _el
      }
    )
  }

  protected changeValue(value): void {
    this.mutatedValue = value
    this.check()
    this.change(value)
  }

  public check(): boolean {
    return (this.mutatedValue || !this.notNull)
  }

  @Watch("value")
  protected updateMutatedValue(): void {
    this.mutatedValue = this.value ? this.value.toString() : ""
  }

  protected get fieldColor() {
    return this.onPrimary ? OxThemeCore.whiteMediumEmphasis : undefined
  }

  protected get fieldBG() {
    return this.onPrimary ? OxThemeCore.primary400 : undefined
  }

  protected get errorMessage(): string {
    return this.state === 'error' || this.state === 'error?' ? this.message.toString() : ''
  }

  protected get hint(): string {
    return this.state === 'info' ? this.message.toString() : ''
  }

  protected get persistentHint(): boolean {
    return this.state === 'info'
  }

  protected get success(): boolean {
    return this.state === 'success'
  }

  protected get error(): boolean {
    return this.state === 'error' || (this.state === 'error?' && this.message !== '')
  }

  protected get labelComputed(): string {
    return this.label + (this.notNull && this.label ? " *" : "")
  }

  public static controlFields(fieldList: object, object): {fields: object, state: boolean} {
    let fieldsOk = true
    let fields = fieldList
    Object.keys(fields).forEach(
      (field) => {
        if ([null, undefined, "", 0].indexOf(object[field]) !== -1 || (Array.isArray(object[field]) && object[field].length === 0)) {
          fields[field] = OxVue.str('TammDhePatientSelection-missing-field')
          fieldsOk = false
          return
        }
        fields[field] = ""
      }
    )
    return {
      fields: fields,
      state : fieldsOk
    }
  }
}
