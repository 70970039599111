/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import { Component, Vue } from 'vue-property-decorator'
import OxParametersApi from './OxParametersApi/OxParametersApi'
import OxVueApi from './OxVueApi'

/**
 * OxVue
 *
 * Surcharge Vue : Propose les méthodes de base pour les composants visibles
 */
@Component
export default class OxVue extends Vue {
    // Etat du composant.
    public active = true

    public loaded = true

    /**
    * Traduction d'une chaine de caractère
    *
    * @param key    string  Clef de traduction
    * @param plural boolean Utilisation du pluriel
    *
    * @return string
    */
    protected tr(key: string, plural = false): string {
        return OxVue.str(key, plural)
    }

    /**
    * Traduction d'une chaine de caractère
    *
    * @param key    string  Clef de traduction
    * @param plural boolean Utilisation du pluriel
    *
    * @return string
    */
    public static str(key: string, plural = false): string {
        return OxParametersApi.tr(key + (plural ? "|pl" : ""))
    }

    /**
    * Lance le chargement de l'application
    */
    protected load(): void {
        OxVueApi.load()
        this.loaded = false
    }

    /**
    * Désactive l'état de chargement de l'application
    */
    protected unload(): void {
        OxVueApi.unload()
        this.loaded = true
    }

    protected async conf(key: string): Promise<string> {
        return await OxParametersApi.conf(key)
    }

    protected capitalize(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1)
    }
}
