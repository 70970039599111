import ClvrFieldSpecificationsStoreState from './ClvrFieldSpecificationsStoreState'
import ClvrFieldSpecificationsStoreGetters from './ClvrFieldSpecificationsStoreGetters'
import ClvrFieldSpecificationsStoreMutations from './ClvrFieldSpecificationsStoreMutations'
import ClvrFieldSpecificationsStoreActions from './ClvrFieldSpecificationsStoreActions'

export default {
    fieldSpecifications: {
        namespaced: false,
        state: ClvrFieldSpecificationsStoreState,
        getters: ClvrFieldSpecificationsStoreGetters,
        mutations: ClvrFieldSpecificationsStoreMutations,
        actions: ClvrFieldSpecificationsStoreActions
    }
}
