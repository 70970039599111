import { ChainingPatientServiceHistoryStatus } from '../../../chaining/vue/types/ChainingPatientService/ChainingPatientServiceHistoryStatus'
import ChainingUser from '../../../chaining/vue/classes/ChainingUser'
import AllergyFoodPatientHistoryDetails from './AllergyFoodPatientHistoryDetails'

export default class AllergyFoodPatientHistory {
    constructor(
        public status: ChainingPatientServiceHistoryStatus,
        public user: ChainingUser,
        public date: Date,
        public details: AllergyFoodPatientHistoryDetails,
        public dateFormated?: string,
        public fullNameUser?: string
    ) {}
}
