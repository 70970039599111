import { ApiDataAdapter } from '@internal-libraries/kheops-ui-lib'
import AllergyFoodPatientHistoryDetails from '../types/AllergyFoodPatientHistoryDetails'
import { AllergyFoodPatientHistoryDetailsDto } from '../types/AllergyFoodPatientHistoryDetailsDto'
import { get } from 'lodash'
import { AllergyAPIAdapter } from '../adapter/AllergyApiAdapter'

export class AllergyFoodPatientHistoryDetailsApiAdapter implements ApiDataAdapter<AllergyFoodPatientHistoryDetails> {
    createClass(apiData: AllergyFoodPatientHistoryDetailsDto): AllergyFoodPatientHistoryDetails {
        const newValues = get(apiData, 'newValues')
        const oldValues = get(apiData, 'oldValues')
        return new AllergyFoodPatientHistoryDetails(
            newValues ? new AllergyAPIAdapter().createClass(newValues) : undefined,
            oldValues ? new AllergyAPIAdapter().createClass(oldValues) : undefined,

        )
    }
}
