export default {
    setBaseUrl: (state, baseUrl: string) => {
        state.baseUrl = baseUrl
    },
    setRootUrl: (state, rootUrl: string) => {
        state.rootUrl = rootUrl
    },
    addLoading: (state) => {
        state.loadings.push("_")
    },
    removeLoading: (state, vueId: string) => {
        state.loadings.shift()
    },
    resetLoading: (state) => {
        state.loadings = []
    }
}
