/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import { Component, ProvideReactive } from 'vue-property-decorator'
import OxVue from '../OxVue'
import { KhSnackbarManager, SnackbarManager } from '@internal-libraries/kheops-ui-lib'
import { Nullable } from '@internal-libraries/kheops-ui-lib'

/**
 * OxVueWrap
 *
 * Wrapper général de l'application Vue.
 */
@Component({
    components: {
        KhSnackbarManager,
    },
})
export default class OxVueWrap extends OxVue {
    @ProvideReactive() public snackbarManager: Nullable<SnackbarManager> = null
}
