import ClvrApiCacheStoreState from './ClvrApiCacheStoreState'
import ClvrApiCacheStoreGetters from './ClvrApiCacheStoreGetters'
import ClvrApiCacheStoreMutations from './ClvrApiCacheStoreMutations'
import ClvrApiCacheStoreActions from './ClvrApiCacheStoreActions'

export default {
    apiCache: {
        namespaced: false,
        state: ClvrApiCacheStoreState,
        getters: ClvrApiCacheStoreGetters,
        mutations: ClvrApiCacheStoreMutations,
        actions: ClvrApiCacheStoreActions
    }
}
