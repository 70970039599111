export default {
    conf: (state) => (conf) => {
        if (typeof (state.configurations[conf]) !== "undefined") {
            return state.configurations[conf]
        }
        return "undefined"
    },
    gconf: (state) => (gconf) => {
        if (typeof (state.group_configurations[gconf]) !== "undefined") {
            return state.group_configurations[gconf]
        }
        return "undefined"
    },
    pref: (state) => (label) => {
        return typeof (state.preferences[label]) !== 'undefined' ? state.preferences[label] : false
    },
    tr: (state) => (key) => {
        return typeof (state.locales[key]) !== 'undefined' ? state.locales[key] : false
    },
    trs: (state) => {
        return state.locales
    },

    hasConfigurationPromise: (state) => (conf) => {
        return typeof (state.configuration_promises[conf]) !== 'undefined'
    },
    configurationPromise: (state) => (conf) => {
        return typeof (state.configuration_promises[conf]) === 'undefined' ? false : state.configuration_promises[conf]
    },
    hasGroupConfigurationPromise: (state) => (conf) => {
        return typeof (state.group_configuration_promises[conf]) !== 'undefined'
    },
    groupConfigurationPromise: (state) => (conf) => {
        return typeof (state.group_configuration_promises[conf]) === 'undefined' ? false : state.group_configuration_promises[conf]
    },
}
