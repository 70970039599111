import ClvrGlobalConfigStoreState from './ClvrGlobalConfigStoreState'
import ClvrGlobalConfigStoreGetters from './ClvrGlobalConfigStoreGetters'
import ClvrGlobalConfigStoreMutations from './ClvrGlobalConfigStoreMutations'
import ClvrGlobalConfigStoreActions from './ClvrGlobalConfigStoreActions'

export default {
    globalConfig: {
        namespaced: true,
        state: ClvrGlobalConfigStoreState,
        getters: ClvrGlobalConfigStoreGetters,
        mutations: ClvrGlobalConfigStoreMutations,
        actions: ClvrGlobalConfigStoreActions
    }
}
