/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import OxParametersProvider from './OxParametersProvider'
import OxStoreCore from '../OxStores/OxStoreCore'

/**
 * OxParametersApi
 *
 * Gestion des configuration, préférences et locales de l'application
 */
export default class OxParametersApi {
  public static async conf(conf: string): Promise<string> {
    if (OxStoreCore.getters['globalConfig/conf'](conf) === "undefined") {
      if (!OxStoreCore.getters.hasConfigurationPromise(conf)) {
        OxStoreCore.commit('globalConfig/setConfigurationPromise', {
          conf: conf,
          promise: new Promise(async (resolve) => {
            const configuration = await OxParametersProvider.loadConfiguration(conf)
            OxStoreCore.commit("globalConfig/setConfiguration", {name: conf, value: configuration})
            OxStoreCore.commit("globalConfig/removeConfigurationPromise", conf)

            resolve(configuration)
          })
          }
        )
      }
      return OxStoreCore.getters['globalConfig/configurationPromise'](conf)
    }
    const configValue = OxStoreCore.getters['globalConfig/conf'](conf);
    if (configValue === "undefined") {
      return ""
    }
    return configValue
  }

  /**
   * Récupération d'une préférence
   *
   * @param pref string Clef de la préférence à récupérer
   *
   * @return string
   */
  public static pref(pref: string): string {
    return OxStoreCore.getters['globalConfig/pref'](pref);
  }

  /**
   * Renseigne une préférence
   *
   * @param label string Clef de la préférence
   * @param value string Valeur de la préférence
   */
  public static setPref(label: string, value: string): void {
    OxStoreCore.commit('globalConfig/setPreference', {name: label, value: value});
  }

  /**
   * Renseigne plusieurs préférences
   *
   * @param prefs {name: string; value: string}[] Ensemble de préférences
   */
  public static setPrefs(prefs): void {
    OxStoreCore.commit('globalConfig/setPreferences', prefs)
  }

  /**
   * Récupération d'une traduction
   *
   * @param key string Clef de traduction
   *
   * @return string
   */
  public static tr(key: string): string {
    return OxStoreCore.getters['globalConfig/tr'](key)
  }

  /**
   * Renseigne une traduction
   *
   * @param key        Clef de traduction
   * @param traduction Valeur de traduction
   */
  public static setTr(key: string, traduction: string): void {
    OxStoreCore.commit('globalConfig/setLocale', {key: key, traduction: traduction})
  }

  /**
   * Renseigne un ensemble de traductions
   *
   * @param traductions {key: string; traduction: string}[] Ensemble de traductions
   */
  public static setTrs(traductions: any): void {
    OxStoreCore.commit('globalConfig/setLocales', traductions)
  }
}
