/**
 * @author  Kheops Technologies SA <devops@kheops.ch>
 * @copyright Kheops Technologies SA
 */
export default {
    'VueAtomes': () => import(/* webpackChunkName: "VueAtomes" */ '../../modules/dPdeveloppement/vue/VueAtomes/VueAtomes.vue'),
    'VueLayouts': () => import(/* webpackChunkName: "VueLayouts" */ '../../modules/dPdeveloppement/vue/VueLayouts/VueLayouts.vue'),
    'ChainingMain': () => import(/* webpackChunkName: "ChainingMain" */ '../../modules/chaining/vue/Components/ChainingMain/ChainingMain.vue'),
    'ChainingManagement': () => import(/* webpackChunkName: "ChainingManagement" */ '../../modules/chaining/vue/Components/ChainingManagement/ChainingManagement.vue'),
    'ChainingImport': () => import(/* webpackChunkName: "ChainingImport" */ '../../modules/chaining/vue/Components/ChainingImport/ChainingImport.vue'),
    'CodingInformationDashboard': () => import(/* webpackChunkName: "StRheaCoding" */ '../../modules/soins/vue/components/CodingInformationDashboard.vue'),
    'ChainingOperation': () => import(/* webpackChunkName: "ChainingOperation" */ '../../modules/chaining/vue/Components/ChainingOperation/ChainingOperation.vue'),
    'ChainingConsultation': () => import(/* webpackChunkName: "ChainingConsultation" */ '../../modules/chaining/vue/Components/ChainingConsultation/ChainingConsultation.vue'),
    'ChainingManagementContext': () => import(/* webpackChunkName: "ChainingManagementContext" */ '../../modules/chaining/vue/Components/ChainingManagementContext/ChainingManagementContext.vue'),
    'ChainingReplaceService': () => import(/* webpackChunkName: "ChainingReplaceService" */ '../../modules/chaining/vue/Components/ChainingReplaceService/ChainingReplaceService.vue'),
    'ChainingCareFollowUp': () => import(/* webpackChunkName: "ChainingCareFollowUp" */ '../../modules/chaining/vue/Components/ChainingCareFollowUp/ChainingCareFollowUp.vue'),
    'ChainingServiceLinkedStay': () => import(/* webpackChunkName: "ChainingServiceLinkedStay" */ '../../modules/chaining/vue/Components/ChainingServiceLinkedStay/ChainingServiceLinkedStay.vue'),
    'AllergyReactionManagement': () => import(/* webpackChunkName: "AllergyReactionManagement" */ '../../modules/dPpatients/vue/Components/AllergyReactionManagement/AllergyReactionManagement.vue'),
    'AllergyFoodManagement': () => import(/* webpackChunkName: "AllergyFoodManagement" */ '../../modules/dPpatients/vue/Components/AllergyFoodManagement/AllergyFoodManagement.vue'),
    'AllergiesEntry': () => import(/* webpackChunkName: "AllergiesEntry" */ '../../modules/soins/vue/components/AllergiesEntry/AllergiesEntry.vue'),
    'DietManagement': () => import(/* webpackChunkName: "DietManagement" */ '../../modules/dPpatients/vue/Components/DietManagement/DietManagement.vue'),
    'MedidataStatus': () => import(/* webpackChunkName: "MedidataStatus" */ '../../modules/medidata/vue/Components/MedidataStatus.vue'),
    'MedidataApiStatus': () => import(/* webpackChunkName: "MedidataStatus" */ '../../modules/medidata/vue/Components/MedidataApiStatus.vue'),
    'DietPrescription': () => import(/* webpackChunkName: "DietPrescription" */ '../../modules/dPprescription/vue/Components/DietPrescription/DietPrescription.vue'),
    'TarmedDeliveryListing': () => import(/* webpackChunkName: "TarmedDeliveryListing" */ '../../modules/tarmed/vue/Components/TarmedDelivery/TarmedDeliveryListing.vue'),
    'ClvrFixDuplicateKeyTranslations': () => import(/* webpackChunkName: "ClvrFixDuplicateKeyTranslations" */ '../../modules/dPdeveloppement/vue/Components/ClvrFixDuplicateKeyTranslations/ClvrFixDuplicateKeyTranslations.vue'),
    'ClvrPrscModal': () => import(/* webpackChunkName: "ClvrPrscModal" */ '../../modules/prescriptionClevEHR/vue/components/ClvrPrscModal/ClvrPrscModal.vue'),
    'ClvrPrscModalTest': () => import(/* webpackChunkName: "ClvrPrscModalTest" */ '../../modules/prescriptionClevEHR/vue/components/ClvrPrscModalTest/ClvrPrscModalTest.vue'),
    'ClvrPrscFrequenciesManagement': () => import(/* webpackChunkName: "ClvrPrscFrequenciesManagement" */ '../../modules/prescriptionClevEHR/vue/components/ClvrPrscFrequenciesManagement/ClvrPrscFrequenciesManagement.vue'),
    'ClvrPrscProductAutocomplete': () => import(/* webpackChunkName: "ClvrPrscProductAutocomplete" */ '../../modules/prescriptionClevEHR/vue/components/ClvrPrscProductAutocomplete/ClvrPrscProductAutocomplete.vue'),
    'ClvrPrscCreatePrescription': () => import(/* webpackChunkName: "ClvrPrscCreatePrescription" */ '../../modules/prescriptionClevEHR/vue/components/ClvrPrscCreatePrescription/ClvrPrscCreatePrescription.vue'),
    'ClvrPrscPosologyCustomized': () => import(/* webpackChunkName: "ClvrPrscPosologyCustomized" */ '../../modules/prescriptionClevEHR/vue/components/ClvrPrscPosology/ClvrPrscPosologyFrequencySelector/ClvrPrscPosologyCustomized/ClvrPrscPosologyCustomized.vue'),
    'ClvrPrcvConfigurationTools': () => import(/* webpackChunkName: "ClvrPrcvConfigurationTools" */ '../../modules/prescriptionClevEHR/vue/components/ClvrPrcvConfigurationTools/ClvrPrcvConfigurationTools.vue'),
    'ClvrPrscConfigurationTools': () => import(/* webpackChunkName: "ClvrPrscConfigurationTools" */ '../../modules/dPprescription/vue/Components/ClvrPrscConfigurationTools/ClvrPrscConfigurationTools.vue'),
}
