export default {
    getSIHParameters: (state) => {
        return {
            sihType: state.sih_type,
            sihId: state.sih_id,
            sihUrl: state.sih_url,
            sihGroupId: state.sih_group_id,
            tammPatientId: state.tamm_patient_id,
            sihCabinetId: state.sih_cabinet_id
        }
    }
}
