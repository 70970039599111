export default {
    setSIHParameters: (
        state, sihParameters: {
            sihType: string,
            sihId: string,
            sihUrl: string,
            sihGroupId: string,
            tammPatientId: string,
            sihCabinetId: string
        }
    ) => {
        state.sih_id = sihParameters.sihId
        state.sih_url = sihParameters.sihUrl
        state.sih_type = sihParameters.sihType
        state.sih_group_id = sihParameters.sihGroupId
        state.tamm_patient_id = sihParameters.tammPatientId
        state.sih_cabinet_id = sihParameters.sihCabinetId
    }
}
