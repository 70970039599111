/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import {
  mdiRefresh,
  mdiHelp,
  mdiMagnify,
  mdiCheck,
  mdiWindowClose,
  mdiCalendar,
  mdiClockOutline,
  mdiScissorsCutting,
  mdiDelete,
  mdiDomain,
  mdiDoctor,
  mdiPlus,
  mdiMinus,
  mdiContentCut,
  mdiGenderMale,
  mdiGenderFemale,
  mdiAccount,
  mdiArrowLeft,
  mdiChevronRight,
  mdiCurrencyEur,
  mdiChevronLeft,
  mdiContentSaveAll,
  mdiLink,
  mdiLinkPlus,
  mdiLinkLock,
  mdiTrashCanOutline,
  mdiArrowRightBoldOutline,
  mdiArrowLeftBoldOutline,
  mdiClose,
  mdiTimerSand, mdiAccountMultipleRemove, mdiLock, mdiDotsVertical, mdiDotsHorizontal, mdiChevronDoubleRight, mdiMenuRightOutline, mdiMenuRight, mdiMenuLeft
} from '@mdi/js';

const icons = {
  'add': mdiPlus,
  'account': mdiAccount,
  'accountMultipleRemove': mdiAccountMultipleRemove,
  'calendar': mdiCalendar ,
  'save': mdiContentSaveAll,
  'cancel': mdiWindowClose,
  'check': mdiCheck,
  'chevronLeft': mdiChevronLeft,
  'chevronRight': mdiChevronRight,
  'currency': mdiCurrencyEur,
  'delete': mdiDelete,
  'female': mdiGenderFemale,
  'hDots': mdiDotsHorizontal,
  'help': mdiHelp,
  'intervention': mdiContentCut,
  'lock': mdiLock,
  'male': mdiGenderMale,
  'praticien': mdiDoctor,
  'previous': mdiArrowLeft,
  'refresh': mdiRefresh,
  'remove': mdiMinus,
  'search': mdiMagnify,
  'sejour': mdiDomain,
  'time': mdiClockOutline,
  'timerSand': mdiTimerSand,
  'vDots': mdiDotsVertical,
  'link': mdiLink,
  'linkAdd': mdiLinkPlus,
  'linkLock': mdiLinkLock,
  'trash': mdiTrashCanOutline,
  'arrowRightBold': mdiArrowRightBoldOutline,
  'arrowLeftBold': mdiArrowLeftBoldOutline,
  'plus': mdiPlus,
  'minus': mdiMinus,
  'chevronDoubleRight': mdiChevronDoubleRight,
  'menuRight': mdiMenuRight,
  'menuLeft': mdiMenuLeft,
  'menuRightOutline': mdiMenuRightOutline,
  'close': mdiClose
}

export default class OxIconCore {
  static get(icon: string) {
    return typeof(icons[icon]) !== "undefined" ? icons[icon] : icons.help
  }
}
