export default {
    setConfiguration: (state, conf: { name: string; value: any }) => {
        state.configurations[conf.name] = conf.value
    },
    setGroupConfiguration: (state, conf: { name: string; value: any }) => {
        state.group_configurations[conf.name] = conf.value
    },
    setPreference: (state, pref: { name: string; value: any }) => {
        state.preferences[pref.name] = pref.value
    },
    setPreferences: (state, prefs: any) => {
        state.preferences = prefs
    },
    setLocale: (state, locale: { key: string; traduction: string }) => {
        state.locales[locale.key] = locale.traduction
    },
    setLocales: (state, locales: any) => {
        state.locales = locales
    },
    setConfigurationPromise: (state, params: { conf: string, promise: Promise<string> }) => {
        if (typeof (state.configuration_promises[params.conf]) !== 'undefined') {
            return
        }
        // @ts-ignore
        state.configuration_promises[params.conf] = params.promise
    },
    removeConfigurationPromise: (state, conf: string) => {
        if (typeof (state.configuration_promises[conf]) === 'undefined') {
            return
        }
        delete (state.configuration_promises[conf])
    },
    setGroupConfigurationPromise: (state, params: { conf: string, promise: Promise<string> }) => {
        if (typeof (state.group_configuration_promises[params.conf]) !== 'undefined') {
            return
        }
        // @ts-ignore
        state.group_configuration_promises[params.conf] = params.promise
    },
    removeGroupConfigurationPromise: (state, conf: string) => {
        if (typeof (state.group_configuration_promises[conf]) === 'undefined') {
            return
        }
        delete (state.group_configuration_promises[conf])
    }
}
