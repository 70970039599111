/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import Vue from 'vue'
import Vuex from 'vuex'

import ClvrAlertStoreModule from './Modules/Alert/ClvrAlertStoreModule'
import ClvrApiCacheStoreModule from './Modules/ApiCache/ClvrApiCacheStoreModule'
import ClvrEventsStoreModule from './Modules/Events/ClvrEventsStoreModule'
import ClvrFieldSpecificationsStoreModule from './Modules/FieldSpecifications/ClvrFieldSpecificationsStoreModule'
import ClvrGlobalConfigStoreModule from './Modules/GlobalConfig/ClvrGlobalConfigStoreModule'
import ClvrMainParametersStoreModule from './Modules/MainParameters/ClvrMainParametersStoreModule'
import ClvrSIHParametersStoreModule from './Modules/SIHParameters/ClvrSIHParametersStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        ...ClvrAlertStoreModule,
        ...ClvrApiCacheStoreModule,
        ...ClvrEventsStoreModule,
        ...ClvrFieldSpecificationsStoreModule,
        ...ClvrGlobalConfigStoreModule,
        ...ClvrMainParametersStoreModule,
        ...ClvrSIHParametersStoreModule
    }
})
