import ClvrSIHParametersStoreState from './ClvrSIHParametersStoreState'
import ClvrSIHParametersStoreGetters from './ClvrSIHParametersStoreGetters'
import ClvrSIHParametersStoreMutations from './ClvrSIHParametersStoreMutations'
import ClvrSIHParametersStoreActions from './ClvrSIHParametersStoreActions'

export default {
    sihParameters: {
        namespaced: false,
        state: ClvrSIHParametersStoreState,
        getters: ClvrSIHParametersStoreGetters,
        mutations: ClvrSIHParametersStoreMutations,
        actions: ClvrSIHParametersStoreActions
    }
}
