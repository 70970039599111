/**
 * @package Mediboard\System
 * @author  Kheops Technologies SA <devops@kheops.ch>
 * @copyright Kheops Technologies SA
 */

import {Component, Prop, Watch} from 'vue-property-decorator'
import OxVicon from '../OxIcon/OxVicon.vue'
import OxFieldText from '../OxField/OxFieldText'
import { validationMixin } from 'vuelidate'
import { minValue, required } from 'vuelidate/lib/validators'
import OxVue from '../../Core/OxVue'
import { EventBus } from './../Events/EventBus.js'
/**
 * Numeric stepper to type quantities
 */
@Component({
    components: { OxVicon },
    mixins: [validationMixin],
    validations: {
        inputQuantityValue: {
            required,
            minValue: minValue(0),
        },
    },
})
export default class QuantityPicker extends OxFieldText {
    //= ========= PROPS
    @Prop()
    public padding!: number

    @Prop({ type: Boolean })
    public allowMinus!: boolean

    @Prop({ type: Boolean })
    public inputEditionDisabled!: boolean

    @Prop({ default: 'gray' })
    public buttonColors?: string

    @Prop({ default: 0 })
    public quantity!: number

    @Prop()
    public minValue!: number

    @Prop()
    public maxValue!: number

    @Prop()
    public disabled!: boolean

    private get minusButtonDisabled(): boolean {
        return this.disabled || this.quantityValue === 0
    }

    private get plusButtonDisabled(): boolean {
        return this.disabled || this.quantityValue === this.maxValue
    }

    private get inputTextDisabled(): boolean {
        return this.disabled || this.inputEditionDisabled
    }

    //= ========= DATA

    private currentPadding = 1
    private quantityValue = 0
    private formIsValidated = false
    private submitted = false
    private inputQuantityValue = 0
    private avoidWatcherToEmit = false

    created() {
        EventBus.$on('submit-quantity-form', () => this.submitQuantityPickerForm())
    }

    beforeDestroy() {
        EventBus.$off('submit-quantity-form')
    }

    private submitQuantityPickerForm() {
        this.submitted = true
        this.$v.$touch()
        this.formIsValidated = !(this.$v.quantityValue.$invalid && this.$v.quantityValue.$anyError)
    }

    /**
     * Reduce the quantity
     */
    public minus(): void {
        if (!this.disabled) {
            this.quantityValue -= this.currentPadding
            this.$v.$touch()
        }
    }

    /**
     * Augment the quantity
     */
    public plus(): void {
        if (!this.disabled) {
            this.quantityValue += this.currentPadding
            this.$v.$touch()
        }
    }

    public get quantityErrors(): string[] | void {
        const errors: string[] = []
        if (this.quantityValue > this.maxValue) {
            errors.push(OxVue.str('quantity-too-high'))
        }
        if (this.quantityValue < this.minValue) {
            errors.push(OxVue.str('quantity-too-low'))
        }
        if (!this.$v.quantityValue.$dirty) return errors
    }

    protected mounted(): void {
        this.quantityValue = +this.quantity
        this.inputQuantityValue = this.quantityValue
        this.currentPadding = this.padding !== undefined ? this.padding : this.currentPadding
    }

    /**
     * Update the component's internl quantity
     * @private
     */
    private updateQuantity() {
        this.$emit('update-quantity', this.quantityValue)
    }

    private updateQuantityFromInput(newQuantity: string) {
        this.quantityValue = +newQuantity
    }

    /**
     * Watching the quantity, applying the parameters
     * If the new value set is falsy, it set back the old one and avoid the emit emit
     * @param newValue
     * @param oldValue
     * @private
     */
    @Watch('quantityValue')
    private onQuantityValueChange(newValue: number, oldValue: number): void {
        if ((this.maxValue === undefined || newValue <= this.maxValue) &&
            ((newValue >= 0 || this.allowMinus) && (this.minValue === undefined || newValue >= this.minValue))) {
            if (this.avoidWatcherToEmit) {
                this.avoidWatcherToEmit = false
            } else {
                this.updateQuantity()
            }
        } else if (newValue === oldValue) {
            // avoid infinite loop in case of wrong data from DB
            return;
        } else {
            this.avoidWatcherToEmit = true
            this.quantityValue = oldValue
        }
        this.inputQuantityValue = this.quantityValue
    }
}
