/**
 * @package Mediboard\System
 * @author  SAS OpenXtrem <dev@openxtrem.com>
 * @license https://www.gnu.org/licenses/gpl.html GNU General Public License
 * @license https://www.openxtrem.com/licenses/oxol.html OXOL OpenXtrem Open License
 */

import { Component, Prop } from 'vue-property-decorator'
import OxVue from "../../Core/OxVue"
import OxVicon from "../OxIcon/OxVicon"

/**
 * OxButton
 *
 * Composant de bouton
 */
@Component({ components: { OxVicon } })
export default class OxButton extends OxVue {
  // Label du bouton
  @Prop({default: ""})
  private label!: string
  // Style principal du bouton
  @Prop({default: "secondary"})
  private buttonStyle!: "primary"|"secondary"|"tertiary"|"tertiary-dark"
  // Icone associé au bouton
  @Prop({default:""})
  private icon!: string
  // Titre du bouton (hover)
  @Prop({default:""})
  private title!: string
  @Prop({default:""})
  private customClass!: string
  @Prop({default: 'right'})
  private iconSide!: "right"|"left"
  @Prop({default: false})
  private small!: boolean
  @Prop({default: false})
  private vuetifySmall!: boolean
  @Prop({default: undefined})
  private dark!: boolean
  @Prop({default: undefined})
  private depressed!: boolean
  @Prop({default: undefined})
  private iconButton!: boolean
  @Prop({default: false})
  private disabled!: boolean
  @Prop({default: false})
  private loading!: boolean

  /**
   * Remontée de l'événement click
   */
  private click(event: MouseEvent): void {
    if (this.loading) {
      return
    }
    this.$emit('click', event)
  }

  private get buttonColor() {
    if (this.buttonStyle === "tertiary-dark") {
      return null
    }
    return this.buttonStyle === "tertiary" ? "secondary" : "primary"
  }

  private get buttonWidth(): number|undefined {
    return this.small ? 24 : undefined
  }

  private get iconSize(): number {
    return this.small ? 18 : 24
  }
}
